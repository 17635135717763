<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="text"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  metaInfo: {
    title: 'PrivacyPolicy',
    link: []
  },
  data () {
    return {
      text: `
      <br><br>
     1. Data We Collect<br><br>
When you use NBVR services, we will record your Oculus id. We also use cookies to store your account information for a convenient browsing experience.<br><br>

2. How We Use Your Data<br><br>
Under limited circumstances we may share personal information with third parties as well, including when complying with legal matters and also when ensuring the security of our site and related services.<br><br>

3. Your Choices and Obligations<br><br>
Personal information fields in your profile, outside of the necessary information to process and approve your account, are entirely optional and at your discretion to provide. Your account information at signup, specifically your valid ISP, college, or workplace email address, is necessary to post on NBVR and to ensure that we are not attacked by anonymous, malicious users. If you do not wish to provide this information, please do not register to post at NBVR.<br><br>

         `,
      project: ' Netboom Game +'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || ' Netboom Game +'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #f0f0f0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
